import * as React from 'react';
import { useEffect, useState, useLayoutEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../components/Layout/Layout';
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import Highlight from 'react-highlight';
import Befores from '../../../components/Befores/Befores';
import ReadMore from '../../../components/ReadMore/ReadMore';
import 'highlight.js/styles/panda-syntax-dark.css';

const ConnectApi = () => {
  useLayoutEffect(() => {}, []);

  const baseURL = 'https://connect.consupedia.com/api/v2/';

  return (
    <Layout>
      <title>Consupedia - Connect API Reference</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/webhook/#about"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId);
              }}
              items={[
                {
                  title: 'Connect API',
                  itemId: '/api-reference/connect',
                  subNav: [
                    {
                      title: 'Authentication',
                      itemId: '/api-reference/connect/#authenticate'
                    }
                  ]
                },
                {
                  title: '/products/',
                  itemId: '/api-reference/connect/#products',
                  subNav: [
                    {
                      title: 'Overview',
                      itemId: '/api-reference/connect/#overview'
                    }
                  ]
                },
                {
                  title: '/subscribe/',
                  itemId: '/api-reference/connect/#subscribe',
                  subNav: [
                    {
                      title: 'account',
                      itemId: '/api-reference/connect/#account',
                      elemBefore: () => <Befores type="get" />
                    },
                    {
                      title: 'add',
                      itemId: '/api-reference/connect/#add',
                      elemBefore: () => <Befores type="post" />
                    },
                    {
                      title: 'more/add',
                      itemId: '/api-reference/connect/#more',
                      elemBefore: () => <Befores type="post" />
                    },
                    {
                      title: 'remove',
                      itemId: '/api-reference/connect/#remove',
                      elemBefore: () => <Befores type="del" />
                    },
                    {
                      title: 'unsubscribeById',
                      itemId: '/api-reference/connect/#unsubscribeById',
                      elemBefore: () => <Befores type="del" />
                    }
                  ]
                },
                {
                  title: '/webhook/',
                  itemId: '/api-reference/connect/#webhook',
                  subNav: [
                    {
                      title: 'Trigger',
                      itemId: '/api-reference/connect/#trigger'
                    }
                  ]
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div>
            <span className="spacer" id="connect-api"></span>
            <h2>Connect</h2>
            <p>
              Connect API is our RESTful service for subscribing and querying
              sustainability data for specific products. Subscriptions can be
              used together with our <a href="/webhook">webhook service</a>.
            </p>
            <Highlight>Base url: {baseURL}</Highlight>
            <span className="spacer" id="authenticate"></span>
            <h2>Authenticate</h2>
            <p>
              To authorize your requests, you'll need to use an API key. The key
              should be included in the header of your requests as follows:
            </p>
            <p>
              Header:
              <br />
              Key: X-API-KEY
              <br />
              Value: [your_api_key]
            </p>
            <p>
              To make a sample cURL request using the provided authorization,
              replace [your_api_key] with your actual API key:
            </p>
            <h3>cURL example</h3>
            <Highlight languages={['sh']}>
              curl -X GET '{baseURL}...' -H 'X-API-KEY: your_api_key'
            </Highlight>
            <h3>Python example</h3>
            <Highlight languages={['python']}>
              {`import requests

url = '${baseURL}...'
headers = {
    'X-API-KEY': 'your_api_key'
}

response = requests.get(url, headers=headers)
`}
            </Highlight>
            <h3>Javascript example</h3>
            <Highlight languages={['javascript']}>
              {`const axios = require('axios');

const url = '${baseURL}...';
const headers = {
  'X-API-KEY': 'your_api_key'
};

axios.get(url, { headers })
  .then(response => {
    console.log(response.data);
  })
  .catch(error => {
    console.error(error);
  });`}
            </Highlight>
            <span className="spacer" id="products"></span>
            <h2>/products/</h2>
            <p>
              Endpoints to get sustainability fields of subscribed products.
            </p>
            <a href="/v2/docs/">See documentation here.</a> <br />
            <a href="https://documenter.getpostman.com/view/12683017/2s8YswSXvs">
              See Postman documentation for the API.
            </a>
            <span className="spacer" id="subscribe"></span>
            <h2>/subscribe/</h2>
            <p>A set of endpoints to manage your subscriptions.</p>
            <span className="spacer" id="account"></span>
            <h2>
              <b>
                <code>GET</code>
              </b>{' '}
              /subscribe/account
            </h2>
            <code>{baseURL}subscribe/account</code>
            <p>Get information about your subscriptions.</p>
            <h3>cURL example</h3>
            <Highlight languages={['sh']}>
              curl -X GET
              'https://connect.consupedia.com/api/v2/subscribe/account' -H
              'X-API-KEY: your_api_key'
            </Highlight>
            <h3>Response example</h3>
            <Highlight languages={['json']}>
              {`{
    "status": "active",
    "client": "Demo Company Inc.",
    "subscriptions": {
        "current_count": 123,
        "max_limit": 1000
    }
}`}
            </Highlight>
            <span className="spacer" id="add"></span>
            <h2>
              <b>
                <code>POST</code>
              </b>{' '}
              /subscribe/
            </h2>
            <div>
              <p>
                <code>https://connect.consupedia.com/api/v2/subscribe/</code>
              </p>
              <p>
                This endpoint enables the subscription of products. The request
                should have a JSON object in the body.
              </p>
              <h3 id="request-body">Request body</h3>
              <p>
                The JSON formatted body (Content-type application/json) should
                by an Array of products.
              </p>
              <p>A product is required to have at least gtin field:</p>
              <Highlight languages={['json']}>
                {`[
    {
        "gtin": "123456789010"
    },
    {
        "gtin": "923456789010"
    }
];`}
              </Highlight>
              <p>
                If you want to supply more product data, the following fields
                may also be added:
              </p>
              <Highlight languages={['json']}>
                {`[
    {
      "gtin": STRING,
      "name": STRING,
      "ingredients": STRING,
      "origin": STRING,
      "category": STRING,
      "brand": STRING,
      "size": FLOAT,
      "size_unit": STRING,
      "image_url": TEXT,
      "price": FLOAT,
      "price_currency": STRING,
      "is_eco": INTEGER (1 or 0),
      "certificates": STRING
    }
];`}
              </Highlight>
              <p>
                Here is the available fields in table form with short
                descriptions:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Max length</th>
                    <th>Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>gtin</td>
                    <td>Global Trade Item Number</td>
                    <td>STRING</td>
                    <td>14</td>
                    <td>"0000000000001"</td>
                  </tr>
                  <tr>
                    <td>name</td>
                    <td>Name of the product</td>
                    <td>STRING</td>
                    <td>255</td>
                    <td>"Organisk Äppeljuice"</td>
                  </tr>
                  <tr>
                    <td>ingredients</td>
                    <td>
                      List of ingredients in the product seperated with commas.
                      Procent of ingredient of total product is added with
                      parenthesis after ingredient name.
                    </td>
                    <td>STRING</td>
                    <td>1000</td>
                    <td>"Äpple (60%), vatten (25%), socker (15%)"</td>
                  </tr>
                  <tr>
                    <td>origin</td>
                    <td>Country of origin of the product</td>
                    <td>STRING</td>
                    <td>50</td>
                    <td>"Spanien"</td>
                  </tr>
                  <tr>
                    <td>category</td>
                    <td>Product category</td>
                    <td>STRING</td>
                    <td>50</td>
                    <td>"Juice & fruktdryck"</td>
                  </tr>
                  <tr>
                    <td>brand</td>
                    <td>Brand name of the product</td>
                    <td>STRING</td>
                    <td>100</td>
                    <td>"JuiceCo"</td>
                  </tr>
                  <tr>
                    <td>size</td>
                    <td>Size of the product</td>
                    <td>FLOAT</td>
                    <td>N/A</td>
                    <td>1.5</td>
                  </tr>
                  <tr>
                    <td>size_unit</td>
                    <td>Unit of measurement for the size (e.g. "ml", "g")</td>
                    <td>STRING</td>
                    <td>10</td>
                    <td>"g"</td>
                  </tr>
                  <tr>
                    <td>image_url</td>
                    <td>URL for an image of the product</td>
                    <td>TEXT</td>
                    <td>N/A</td>
                    <td>"https://example.com/juice.jpg"</td>
                  </tr>
                  <tr>
                    <td>price</td>
                    <td>Price of the product</td>
                    <td>FLOAT</td>
                    <td>N/A</td>
                    <td>5.99</td>
                  </tr>
                  <tr>
                    <td>price_currency</td>
                    <td>Currency of the price (e.g. "USD", "EUR", "SEK")</td>
                    <td>STRING</td>
                    <td>3</td>
                    <td>"SEK"</td>
                  </tr>
                  <tr>
                    <td>is_eco</td>
                    <td>Whether the product is eco-friendly (1 or 0)</td>
                    <td>INTEGER</td>
                    <td>N/A</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>certificates</td>
                    <td>List of certificate names of the product</td>
                    <td>STRING</td>
                    <td>1000</td>
                    <td>"Krav,Rainforest Alliance"</td>
                  </tr>
                </tbody>
              </table>
              <h3>Supported Languages</h3>
              <p>For best results, add text data in Swedish or English.</p>
              <h3>Update a subscription with more data</h3>
              <p>
                If you got more/changed product information and would like to
                update your subscription, use the same method and body as a new
                subscription. If the subscription already exists, the
                subscription will be updated.
              </p>
              <h3>cURL example</h3>
              <Highlight languages={['sh']}>
                {`
  curl -X POST 'https://connect.consupedia.com/api/v2/subscribe/' \\
  -H 'X-API-KEY: your_api_key' \\
  -H 'Content-Type: application/json' \\
  -d '[
    {
        "gtin": "123456789010"
    },
     {
        "gtin": "923456789010"
    }
]'`}
              </Highlight>
              <h3>Response example</h3>
              <Highlight languages={['json']}>
                {`{
  "status": "success",
  "message": "Products subscribed",
  "client": "Consupedia",
  "productsSubscribed": 1,
  "unknownProductsSubscribed": 1,
  "productsSkipped": 0
}`}
              </Highlight>
            </div>
            <span className="spacer" id="more"></span>
            <h2>
              <b>
                <code>POST</code>
              </b>{' '}
              /subscribe/more/add
            </h2>
            <div>
              <p>
                <code>
                  https://connect.consupedia.com/api/v2/subscribe/more/add
                </code>
                <br />
                {/* <br />
                <a href="/v2/docs/#tag/subscribe">
                  See the other endpoints under /subscribe here.
                </a> */}
              </p>

              <p>
                This endpoint enables the subscription of products with rich
                product data. The request should have a JSON object in the body.
              </p>
              <h3 id="request-body">Request body</h3>
              <p>
                The JSON formatted body (Content-type application/json) should
                be a product represented as a single object.
              </p>
              <p>
                A product is required to have at least <code>gtin</code> or{' '}
                <code>client_identifier</code> field:
              </p>
              <Highlight languages={['json']}>
                {`{
  "gtin": "12345678901011",
}
`}
              </Highlight>
              <p>
                If you want to supply more product data, the following fields
                may also be added:
              </p>
              <ReadMore>
                <Highlight languages={['json']}>
                  {`{
      "gtin": STRING,
      "client_identifier": STRING,
      "name": STRING,
      "information_provider_gln": STRING,
      "category": STRING,
      "subcategory": INTEGER,
      "brand_name": STRING,
      "external_name": STRING,
      "functional_name": STRING,
      "packaging_label": STRING,
      "country_of_origin": STRING,
      "provenance_statement": STRING,
      "raw_material_origin": STRING,
      "packaging_type": STRING,
      "packaging_material": STRING,
      "packaging_material_quantity": FLOAT,
      "packaging_material_unit": STRING,
      "ingredients": TEXT,
      "size": STRING,
      "alcohol_percentage": FLOAT,
      "preparation_state": STRING,
      "nutrient_basis_quantity": FLOAT,
      "nutrient_basis_unit": STRING,
      "quantity_energy_kj": FLOAT,
      "quantity_energy_kcal": FLOAT,
      "quantity_fat": FLOAT,
      "quantity_part_saturated_fat": FLOAT,
      "quantity_part_monounsaturated_fat": FLOAT,
      "quantity_part_polyunsaturated_fat": FLOAT,
      "quantity_carbohydrate": FLOAT,
      "quantity_part_sugars": FLOAT,
      "quantity_part_polyols": FLOAT,
      "quantity_part_starch": FLOAT,
      "quantity_fibre": FLOAT,
      "quantity_protein": FLOAT,
      "quantity_salt":FLOAT,
      "secondary_preparation_state": STRING,
      "secondary_nutrient_basis_quantity": FLOAT,
      "secondary_nutrient_basis_unit": STRING,
      "secondary_quantity_energy_kj": FLOAT,
      "secondary_quantity_energy_kcal": FLOAT,
      "secondary_quantity_fat": FLOAT,
      "secondary_quantity_part_saturated_fat": FLOAT,
      "secondary_quantity_part_monounsaturated_fat": FLOAT,
      "secondary_quantity_part_polyunsaturated_fat": FLOAT,
      "secondary_quantity_carbohydrate": FLOAT,
      "secondary_quantity_part_sugars": FLOAT,
      "secondary_quantity_part_polyols": FLOAT,
      "secondary_quantity_part_starch": FLOAT,
      "secondary_quantity_fibre": FLOAT,
      "secondary_quantity_protein": FLOAT,
      "secondary_quantity_salt":FLOAT,
      "marketing_message": TEXT,
      "image_original": STRING,
      "image_high_res": STRING,
      "image_low_res": STRING
    }`}
                </Highlight>
              </ReadMore>

              <p>
                Here is the available fields in table form with short
                descriptions:
              </p>
              <ReadMore>
                <table>
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Max length</th>
                      <th>Example</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>gtin</td>
                      <td>Global Trade Item Number</td>
                      <td>STRING</td>
                      <td>14</td>
                      <td>12345678901234</td>
                    </tr>
                    <tr>
                      <td>client_identifier</td>
                      <td>Identifier assigned by the client</td>
                      <td>STRING</td>
                      <td>50</td>
                      <td>CI123456</td>
                    </tr>
                    <tr>
                      <td>name</td>
                      <td>Product name</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Chocolate Bar</td>
                    </tr>
                    <tr>
                      <td>information_provider_gln</td>
                      <td>
                        Global Location Number of the information provider
                      </td>
                      <td>STRING</td>
                      <td>13</td>
                      <td>4000001000001</td>
                    </tr>
                    <tr>
                      <td>category</td>
                      <td>Product category</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Alcoholic Beverages</td>
                    </tr>
                    <tr>
                      <td>subcategory</td>
                      <td>Product subcategory</td>
                      <td>INTEGER</td>
                      <td></td>
                      <td>10820</td>
                    </tr>
                    <tr>
                      <td>brand_name</td>
                      <td>Brand name of the product</td>
                      <td>STRING</td>
                      <td>100</td>
                      <td>Sweet Treats</td>
                    </tr>
                    <tr>
                      <td>external_name</td>
                      <td>Product name used in external systems</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>ChocoBar</td>
                    </tr>
                    <tr>
                      <td>functional_name</td>
                      <td>Functional name of the product</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Snack</td>
                    </tr>
                    <tr>
                      <td>packaging_label</td>
                      <td>Packaging label information</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Recyclable packaging</td>
                    </tr>
                    <tr>
                      <td>country_of_origin</td>
                      <td>Country where the product is originated</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>US</td>
                    </tr>
                    <tr>
                      <td>provenance_statement</td>
                      <td>Statement of product's provenance</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Made in the USA</td>
                    </tr>
                    <tr>
                      <td>raw_material_origin</td>
                      <td>Origin of the raw materials</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Cocoa beans from Ghana</td>
                    </tr>
                    <tr>
                      <td>packaging_type</td>
                      <td>Type of packaging</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Box</td>
                    </tr>
                    <tr>
                      <td>packaging_material</td>
                      <td>Material used for packaging</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Cardboard</td>
                    </tr>
                    <tr>
                      <td>packaging_material_quantity</td>
                      <td>Quantity of packaging material</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>10.5</td>
                    </tr>
                    <tr>
                      <td>packaging_material_unit</td>
                      <td>Unit of measurement for packaging material</td>
                      <td>STRING</td>
                      <td>10</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>ingredients</td>
                      <td>List of ingredients</td>
                      <td>STRING</td>
                      <td>1000</td>
                      <td>
                        Cocoa mass, sugar, cocoa butter, emulsifier (soy
                        lecithin), natural vanilla flavor
                      </td>
                    </tr>
                    <tr>
                      <td>size</td>
                      <td>Product size</td>
                      <td>STRING</td>
                      <td>50</td>
                      <td>100g</td>
                    </tr>
                    <tr>
                      <td>alcohol_percentage</td>
                      <td>Alcohol percentage, if applicable</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>0.0</td>
                    </tr>
                    <tr>
                      <td>preparation_state</td>
                      <td>State of the product before consumption</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td>Ready-to-eat</td>
                    </tr>
                    <tr>
                      <td>nutrient_basis_quantity</td>
                      <td>Quantity for nutrient basis</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>100.0</td>
                    </tr>
                    <tr>
                      <td>nutrient_basis_unit</td>
                      <td>Unit of measurement for nutrient basis</td>
                      <td>STRING</td>
                      <td>10</td>
                      <td>g</td>
                    </tr>
                    <tr>
                      <td>quantity_energy_kj</td>
                      <td>Energy in kilojoules</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>2230.0</td>
                    </tr>
                    <tr>
                      <td>quantity_energy_kcal</td>
                      <td>Energy in kilocalories</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>533.0</td>
                    </tr>
                    <tr>
                      <td>quantity_fat</td>
                      <td>Fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>31.0</td>
                    </tr>
                    <tr>
                      <td>quantity_part_saturated_fat</td>
                      <td>Saturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>0.3</td>
                    </tr>
                    <tr>
                      <td>quantity_part_monounsaturated_fat</td>
                      <td>Monounsaturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>0.1</td>
                    </tr>
                    <tr>
                      <td>quantity_part_polyunsaturated_fat</td>
                      <td>Polyunsaturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>4.0</td>
                    </tr>
                    <tr>
                      <td>quantity_carbohydrate</td>
                      <td>Carbohydrate content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>51.0</td>
                    </tr>
                    <tr>
                      <td>quantity_part_sugars</td>
                      <td>Sugar content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>47.0</td>
                    </tr>
                    <tr>
                      <td>quantity_part_polyols</td>
                      <td>Polyol content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>0.0</td>
                    </tr>
                    <tr>
                      <td>quantity_part_starch</td>
                      <td>Starch content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>4.0</td>
                    </tr>
                    <tr>
                      <td>quantity_fibre</td>
                      <td>Fibre content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>3.0</td>
                    </tr>
                    <tr>
                      <td>quantity_protein</td>
                      <td>Protein content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>7.0</td>
                    </tr>
                    <tr>
                      <td>quantity_salt</td>
                      <td>Salt content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td>0.1</td>
                    </tr>
                    <tr>
                      <td>secondary_preparation_state</td>
                      <td>Secondary state of the product before consumption</td>
                      <td>STRING</td>
                      <td>255</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_nutrient_basis_quantity</td>
                      <td>Secondary quantity for nutrient basis</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_nutrient_basis_unit</td>
                      <td>Secondary unit of measurement for nutrient basis</td>
                      <td>STRING</td>
                      <td>10</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_energy_kj</td>
                      <td>Secondary energy in kilojoules</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_energy_kcal</td>
                      <td>Secondary energy in kilocalories</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_fat</td>
                      <td>Secondary fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_saturated_fat</td>
                      <td>Secondary saturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_monounsaturated_fat</td>
                      <td>Secondary monounsaturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_polyunsaturated_fat</td>
                      <td>Secondary polyunsaturated fat content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_carbohydrate</td>
                      <td>Secondary carbohydrate content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_sugars</td>
                      <td>Secondary sugar content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_polyols</td>
                      <td>Secondary polyol content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_part_starch</td>
                      <td>Secondary starch content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_fibre</td>
                      <td>Secondary fibre content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_protein</td>
                      <td>Secondary protein content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>secondary_quantity_salt</td>
                      <td>Secondary salt content</td>
                      <td>FLOAT</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>marketing_message</td>
                      <td>Marketing message for the product</td>
                      <td>STRING</td>
                      <td>1000</td>
                      <td>
                        Indulge in the rich taste of our premium chocolate bar
                        made with the finest cocoa beans.
                      </td>
                    </tr>
                    <tr>
                      <td>image_original</td>
                      <td>URL of the original product image</td>
                      <td>STRING</td>
                      <td>500</td>
                      <td>
                        https://example.com/images/chocolate_bar_original.jpg
                      </td>
                    </tr>
                    <tr>
                      <td>image_high_res</td>
                      <td>URL of the high-resolution product image</td>
                      <td>STRING</td>
                      <td>500</td>
                      <td>
                        https://example.com/images/chocolate_bar_high_res.jpg
                      </td>
                    </tr>
                    <tr>
                      <td>image_low_res</td>
                      <td>URL of the low-resolution product image</td>
                      <td>STRING</td>
                      <td>500</td>
                      <td>
                        https://example.com/images/chocolate_bar_low_res.jpg
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ReadMore>
              <h3 id="supported-languages">Supported Languages</h3>
              <p>For best results, add text data in Swedish or English.</p>
              <h3 id="update-a-subscription-with-more-data">
                Update a subscription with more data
              </h3>
              <p>
                If you got more/changed product information and would like to
                update your subscription, use the same method and body as a new
                subscription. If the subscription already exists, the
                subscription will be updated.
              </p>
              <h3>cURL example</h3>
              <Highlight languages={['sh']}>
                {`
  curl -X POST 'https://connect.consupedia.com/api/v2/subscribe/add/more' \\
  -H 'X-API-KEY: your_api_key' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "gtin": "123456789010"
  }'
  `}
              </Highlight>

              <h3>Response example</h3>
              <Highlight languages={['json']}>
                {`{
    "status": "success",
    "action": "subscription_created",
    "client": "Demo Company Inc.",
    "subscriptions": {
        "current_count": 124,
        "max_limit": 2000
    }
}`}
              </Highlight>
            </div>
            <span className="spacer" id="remove"></span>
            <h2>
              <b>
                <code>DELETE</code>
              </b>{' '}
              /subscribe/{'{'}gtin{'}'}
            </h2>
            <div>
              <p>
                End a product subscription by calling /subscribe/:gtin with the
                DELETE method.
              </p>
              <code>{baseURL}subscribe/:gtin</code>
              <h3>Path Parameters</h3>
              <ul>
                <li>
                  <strong>id:</strong> Global Trade Item Number (GTIN)
                  <ul>
                    <li>
                      <strong>Description:</strong> The unique identifier for
                      the product.
                    </li>
                    <li>
                      <strong>Type:</strong> String
                    </li>
                    <li>
                      <strong>Example:</strong> "0000000000001"
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>cURL example</h3>
              <Highlight>{`curl --location --request DELETE 'https://connect.consupedia.com/api/v2/subscribe/123456789010' \
--header 'X-API-KEY: your_api_key'`}</Highlight>
            </div>
            {
              // Start Unsubscribe by Client Identifier
            }
            <span className="spacer" id="unsubscribeById"></span>
            <h2>
              <b>
                <code>DELETE</code>
              </b>{' '}
              /subscribe/unsubscribeById/{'{'}client_identifier{'}'}
            </h2>
            <div>
              <p>End a product subscription by client_identifier.</p>
              <code>{baseURL}subscribe/unsubscribeById/:client_identifier</code>
              <h3>Path Parameters</h3>
              <ul>
                <li>
                  <strong>client_identifier:</strong> Your own identifier
                  associated with a subscribed product.
                  <ul>
                    <li>
                      <strong>Description:</strong> The unique identifier for
                      the product.
                    </li>
                    <li>
                      <strong>Type:</strong> String
                    </li>
                    <li>
                      <strong>Example:</strong> "abc0000000000001"
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>cURL example</h3>
              <Highlight>{`curl --location --request DELETE 'https://connect.consupedia.com/api/v2/subscribe/unsubscribeById/abc-123' \
--header 'X-API-KEY: your_api_key'`}</Highlight>

              <h3>Response</h3>
              <Highlight>{`{
    "status": "success",
    "message": "Product unsubscribed",
    "action": "product_unsubscribed",
    "client": "Consupedia"
}`}</Highlight>
            </div>
            {
              // End Unsubscribe by Client Identifier
            }
            <span className="spacer" id="trigger"></span>
            <h2>/webhook/</h2>
            <p>
              This endpoint exposes services to trigger webhook requests. To
              specify your webhook's url, contact{' '}
              <a href="mailto: tech-support@consupedia.com" target="_blank">
                tech-support@consupedia.com
              </a>
              . The response of these services will always be a single product
              object in json. .
            </p>
            <span className="spacer" id="trigger"></span>
            <h2>
              <b>
                <code>GET</code>
              </b>{' '}
              /webhook/trigger/
            </h2>
            <p>
              Trigger a random product to be sent to your webhook url. The
              product is sent with a <code>POST</code> request to the webhook.
            </p>
            <code>{baseURL}webhook/trigger</code>
            <h3>cURL example</h3>
            <Highlight>{`curl --location --request GET 'https://connect.consupedia.com/api/v2/webhook/trigger' \
--header 'X-API-KEY: your_api_key'`}</Highlight>
            <h3>Response example</h3>
            <Highlight languages={['json']}>
              {`{
    "status": "success",
    "action": "webhook_triggered",
    "message": "A random product has been sent."
}`}
            </Highlight>
            <h3>
              Example of the body of the <code>POST</code> request sent the
              webhook url
            </h3>
            <Highlight languages={['json']}>
              {`{
  "id": "05425007723373-7314060000003", // client identifier if sent with the subscription
  "gtin": "5425007723373",
  "climateScore": 40,
  "CO2e": 2.5,
  "novaScore": 1,
  "nutriScore": "E",
  "healthScore": 15,
  "antibioticsResistanceScore": 81,
  "overconsumptionRisk": "",
  "socialJusticeScore": 63,
  "equalityScore": 95,
  "workerRightsScore": 85,
  "animalProtectionScore": null,
  "childLaborScore": 100,
  "biodiversityScore": 82,
  "pesticidesUseScore": 69,
  "fertilizersUseScore": 5,
  "landUseScore": null,
  "waterScore": 32,
  "transportScore": 89,
  "isEco": 1
}`}
            </Highlight>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ConnectApi;
