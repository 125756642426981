import React, { useState } from 'react'
import './ReadMore.scss'

const ReadMore = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleContent() {
    setIsOpen(!isOpen)
  }

  return (
    <div className="readMore">
      <div className={`readMoreContent ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
      <button className="button" onClick={toggleContent}>
        {isOpen ? 'See less' : 'Read more'}
      </button>
    </div>
  )
}

export default ReadMore
